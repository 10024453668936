/* --------------------------------------------------------------------------
| IMPORTS...
|-------------------------------------------------------------------------- */

import { handleOpenScreenReadOnPanel, handleCloseScreenReadOnPanel } from '../../functions/functions-propositions';

/* --------------------------------------------------------------------------
| FILE PROPOSITION READ EVENT PANEL
|-------------------------------------------------------------------------- */

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE ABERTURA DA LEITURA NO PAINEL...
|-------------------------------------------------------------------------- */
window.Echo.channel("read-started")
    .listen(".read-started", (e) => { // Fica ouvindo...
        handleOpenScreenReadOnPanel(); // Abre a leitura...
        window.location.reload();
    });

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE ENCERRAMENTO DA LEITURA NO PAINEL...
|-------------------------------------------------------------------------- */

window.Echo.channel("read-closed")
    .listen(".read-closed", (e) => { // Fica ouvindo...
        handleCloseScreenReadOnPanel(); // Fecha a leitura...
    });
